import loadAnalytics from '@fileee/analytics-core';
import PubSub from 'pubsub-js';
import { isLoggedIn, isProductionEnv } from 'common/utils';
import { GLOBAL_MIXPANEL } from 'common/channel-constants';
import { getLogger } from 'logger';
import { getTrackingParamsToForwardToServer } from 'common/utm-utils';
import { appOpenedEvent } from './event-constants';
import { removeNullValuesFromObject } from 'common/immutable-utils';
import {
  GDPRConsentChangedEvent,
  GDPRCookieName,
  GDPRCookieValueEnum
} from 'constants/cookie-constants';
import { isUrlTrackingDisabled } from 'startup/start-url-actions';
import { TrackPagePropertiesMap, TrackPropertiesMap } from './types';

const logger = getLogger('mixpanel-tracking');

export const WEBSITE_MIXPANEL_DISTINCT_ID_COOKIE_NAME = 'fi-mp-distinct-id';

let cookieConsentChangedPromise: Promise<GDPRCookieValueEnum> = new Promise((resolve) => {
  window.addEventListener(GDPRConsentChangedEvent, () => {
    resolve(window.getCookieValue(GDPRCookieName));
  });
});

function getGdprConsent(): Promise<GDPRCookieValueEnum> {
  if (window.getCookieValue(GDPRCookieName)) {
    return Promise.resolve(window.getCookieValue(GDPRCookieName));
  } else {
    return cookieConsentChangedPromise;
  }
}

function getGdprConsentSync(): GDPRCookieValueEnum {
  return window.getCookieValue(GDPRCookieName);
}

const MIXPANEL_TOKEN = isProductionEnv()
  ? '8e0c3d87848f1c409a22e34d917e4ad2'
  : '4dc8ed6e99f9169fbb56a5703a00cc6e';
const MEASUREMENT_ID = isProductionEnv() ? 'G-K74M2L9K9N' : 'G-F5BQK3CHES';
const PIXEL_ID = isProductionEnv() ? '449297555218475' : 'Not_Existent_Pixel_Id';

function getMixpanelAdditionalSettings() {
  if (isUrlTrackingDisabled()) {
    return {
      property_blacklist: ['$current_url', '$initial_referrer', '$referrer']
    };
  }
  return {};
}

export function startAnalytics() {
  if (BUILD_VAR_IS_EMBEDDED_APP) return;

  subscribeForAnalyticsEvents();

  let currentConsent = getGdprConsentSync();

  if (currentConsent) {
    if (currentConsent === 'all') {
      loadAnalytics({
        mixpanel: {
          token: MIXPANEL_TOKEN,
          enableScriptLoading: true,
          ...getMixpanelAdditionalSettings()
        },
        facebook: {
          pixelId: PIXEL_ID,
          enableScriptLoading: false
        },
        google: {
          measurementId: MEASUREMENT_ID,
          enableScriptLoading: false
        }
      });
    } else {
      loadAnalytics({
        mixpanel: {
          token: MIXPANEL_TOKEN,
          disable_persistence: true,
          enableScriptLoading: true,
          ...getMixpanelAdditionalSettings()
        }
      });
    }
  } else {
    /**
     * We load mixpanel directly in no cookie mode
     * and attach rest of the scripts based on consent
     */
    loadAnalytics({
      mixpanel: {
        token: MIXPANEL_TOKEN,
        enableScriptLoading: true,
        disable_persistence: true,
        ...getMixpanelAdditionalSettings()
      },
      facebook: {
        pixelId: PIXEL_ID,
        enableScriptLoading: false
      },
      google: {
        measurementId: MEASUREMENT_ID,
        enableScriptLoading: false
      }
    });

    getGdprConsent().then((value) => {
      if (value === 'all') {
        window.analytics.loadAdditionalScripts({
          facebook: {
            enableScriptLoading: false
          },
          google: {
            enableScriptLoading: false
          }
        });
        window.analytics.enablePersistence();
      }
    });
  }
}

export function enableDisableMixpanelBasedOnCookie() {
  if (!window.getCookieValue('disable_tracking')) {
    enableTracking();
  } else {
    disableTracking();
  }
}

export function getDistinctMixpanelId() {
  try {
    if (window.mixpanel && window.mixpanel.get_distinct_id) {
      return window.mixpanel.get_distinct_id();
    } else {
      return null;
    }
  } catch (error) {
    logger.error(error);
  }
}

export function resetTracking() {
  enableTracking();
}

export function enableTracking() {
  //remove cookie
  document.cookie = 'disable_tracking=; expires=01 Jan 1970 00:00:01 GMT; path=/';

  window.analytics.unregister('$ignore');

  identifyUserForMixpanel();

  window.analytics.register({ language: document.fileeeBasicVar.language });
  console.log('[IN] Mixpanel enabled ');
}

export function identifyUserForMixpanel() {
  if (!isLoggedIn()) return;

  window.analytics.identity({
    id: document.fileeeBasicVar.actingUserId
      ? document.fileeeBasicVar.actingUserId
      : document.fileeeBasicVar.userId
  });

  window.analytics.register({
    user_id: document.fileeeBasicVar.actingUserId
      ? document.fileeeBasicVar.actingUserId
      : document.fileeeBasicVar.userId
  });
}

export function registerLastTouchUtmParams(lastTouchParams) {
  window.analytics.register(lastTouchParams);
}

let subscriptionToken = null;

function subscribeForAnalyticsEvents() {
  if (subscriptionToken) {
    logger.error(`Already subscribed to mixpanel events`);
    return;
  }
  let subscription = (msg, data) => {
    if (__DEV__) {
      const { eventName, ...rest } = data;
      console.info(
        `Mixpanel tracking disabled in DEV env ${eventName} : ${JSON.stringify(rest, null, 2)}`
      );

      return;
    }

    if (!data) {
      return;
    }

    if (__DEV__) {
      const { eventName, ...rest } = data;
      console.info(
        `Mixpanel tracking disabled in DEV env ${eventName} : ${JSON.stringify(rest, null, 2)}`
      );
      return;
    }

    const { eventName, config, ...rest } = data;

    let remainingProperties = rest;

    if (rest) {
      remainingProperties = removeNullValuesFromObject(remainingProperties);
    }

    window.analytics.track({
      event: eventName,
      properties: remainingProperties,
      // Send to only mixpanel if no config is present by default
      config: config || {
        mixpanel: true
      }
    });
  };

  subscriptionToken = PubSub.subscribe(GLOBAL_MIXPANEL, subscription);
}

export function disableTracking() {
  document.cookie = 'disable_tracking=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
  window.analytics.register({ $ignore: 'true' });
  console.log('[IN] Mixpanel disabled');
}

export function track<E extends keyof TrackPropertiesMap>(
  eventName: E,
  additionalParams: TrackPropertiesMap[E]
) {
  let params = additionalParams ? additionalParams : {};
  const data = { eventName, ...params };
  PubSub.publish(GLOBAL_MIXPANEL, data);
}

export function trackPage<E extends keyof TrackPagePropertiesMap>(
  eventName: E,
  additionalParams: TrackPagePropertiesMap[E]
) {
  let params = additionalParams ? { ...additionalParams } : {};
  const data = { eventName: `PageView - ${eventName}`, ...params };
  PubSub.publish(GLOBAL_MIXPANEL, data);
}

export function trackAppOpen() {
  if (__DEV__) {
    return;
  }

  let utmParams = getTrackingParamsToForwardToServer();

  window.analytics.track({
    event: appOpenedEvent,
    properties: utmParams
  });
}
