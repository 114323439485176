//@ts-check
// DO  NOT USE GWT Stuff in here
import { WebappAxios } from 'api/axios-utils';
import queryString from 'query-string';
import { stopLocalProfiling, startLocalProfiling } from 'common/profiler';
import { logOutExternalUser, setExternalLogIn } from 'common/utils';
import {
  BelboonParam,
  BelboonParamForFoxycart,
  getTrackingParamsToForwardToServer,
  SupportedFoxyCartTrackingParams
} from 'common/utm-utils';
import { removeEmptyKeys } from 'common/object-utils';
import { getConversationParams, getSponsoredBy } from './signup-options-state';
import { AxiosResponse } from 'axios';
import { IAccountTypeEnum } from '@/types/login-flow-types';
import { PublicUserExistentResponse, SupportedLoginOptionsEnum } from '@fileee/components';

export type { IAccountTypeEnum } from '@/types/login-flow-types';

export type IFExistsResponse = {
  userId: string;
  username: string;
  user?: {
    type: 'EXTERNAL' | 'USER';
    username: string;
  };
  scopes: string[];
};

export function getFExists(): Promise<AxiosResponse<IFExistsResponse>> {
  return WebappAxios.get('/f/exists');
}

export function getFStart(): Promise<AxiosResponse<any>> {
  return WebappAxios.get('/f/start');
}

interface IAuthUser {
  participantId: string;
  username: string;
  participantName: string;
  type: any;
}

export interface IAuthResponse {
  userId: string;
  username: string;
  user: IAuthUser;
  actingUser?: IAuthUser;
  redirectPath?: string;
  groups: string[];
}

export function loginUser(
  username: string,
  password: string,
  twoFactorCode: string,
  options = {}
): Promise<IAuthResponse> {
  let conversationParams = removeEmptyKeys(getConversationParams());
  let apiParams: any = {
    username,
    password,
    ...conversationParams,
    ...options
  };
  if (twoFactorCode) {
    apiParams = {
      ...apiParams,
      [`two-factor-token`]: twoFactorCode
    };
  }

  let parsedQuery = queryString.stringify(apiParams);

  return WebappAxios.post('/f/login', parsedQuery).then((response) => {
    return response.data;
  });
}

type ISignUpParams = {
  username: string;
  password: string;
  userType: IAccountTypeEnum;
  enableNewsletter: boolean;
  ['mixpanel:distinctId']: string;
  sponsoredBy?: string;
  conversationToken?: string;
  conversationSecret?: string;
  utm_medium?: string;
  utm_campaign?: string;
  conversationAddWithoutInvite?: boolean;
};

export function signup(
  options: ISignUpParams & Record<string, string | boolean>
): Promise<IAuthResponse> {
  let utmParams = getTrackingParamsToForwardToServer() || {};
  let sponsoredBy = getSponsoredBy();
  let conversationParams = getConversationParams();

  let additionalParams = {
    sponsoredBy: sponsoredBy,
    ...utmParams,
    ...conversationParams,
    ...getAdditionalRegisterParams()
  };

  let finalOptions = Object.assign({}, removeEmptyKeys(additionalParams), options);
  let parsedQuery = queryString.stringify(finalOptions);
  return WebappAxios.post('/f/register', parsedQuery).then((response) => {
    return response.data;
  });
}

export function regsiterCompanyAccount({
  username,
  password,
  acceptedFileeeTos,
  acceptedEETos
}): Promise<IAuthResponse> {
  let utmParams = getTrackingParamsToForwardToServer() || {};
  let apiParams = {
    username,
    password,
    acceptedFileeeTos,
    acceptedEETos,
    ...utmParams
  };
  return WebappAxios.post('/f/register-with-company-account', apiParams).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {string} username
 * @returns Promise<any>
 */
export function resetPassword(username) {
  const options = {
    username
  };
  return WebappAxios.post('/f/reset_password', options).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {any} options
 * @return {Promise<any>}
 */
export function setPassowrd(options) {
  return WebappAxios.post('/f/set_password', options).then((response) => {
    return response.data;
  });
}

export function loginWithToken(token): Promise<IAuthResponse> {
  const apiParams = {
    token
  };
  let parsedQuery = queryString.stringify(apiParams);
  return WebappAxios.post('/f/token/login', parsedQuery).then((response) => {
    return response.data;
  });
}

export function logout() {
  return WebappAxios.post('/f/logout').then((response) => {
    return response.data;
  });
}

export function getExternalCompany(companyId: string): Promise<apiResponse.JsCompany> {
  return WebappAxios.get(`/v1/companies/external/${companyId}`).then((response) => {
    return response.data;
  });
}

/**
 * @param { string } user
 */
export function resendVerificationEmail(user) {
  if (!user) {
    return;
  }
  const apiParams = { email: user };
  let parsedQuery = queryString.stringify(apiParams);
  return WebappAxios.post('/f/resend-activation-mail', parsedQuery).then((response) => {
    return response.data;
  });
}

export interface IApiExternalUserResponse {
  userId: string;
  username: string;
}

export function apiLoginExternalUser(
  conversationToken: string,
  secret = ''
): Promise<IApiExternalUserResponse> {
  startLocalProfiling('Login External User');
  logOutExternalUser();
  const apiParams = {
    token: conversationToken,
    secret: secret
  };
  let parsedQuery = queryString.stringify(apiParams);
  return WebappAxios.post('/f/external/login', parsedQuery).then((response) => {
    setExternalLogIn(response.data.userId, response.data.username);
    stopLocalProfiling('Login External User');
    return response.data;
  });
}

/**
 * @param  {string} conversationToken [description]
 */
export function resendSms(conversationToken) {
  if (!conversationToken) {
    return;
  }
  return WebappAxios.post(`/f/resend-conversation-sms/${conversationToken}`).then((response) => {
    return response.data;
  });
}

export function postUserExistent(email): Promise<PublicUserExistentResponse> {
  const apiParams = { username: email };

  return WebappAxios.post('/f/existent', apiParams).then((response) => {
    return response.data;
  });
}

export function getAdditionalRegisterParams() {
  if (BUILD_VAR_APP_TARGET === 'APP_JAMES') {
    let isProductionEnv = window.location.hostname === 'www.oevdigital.de';
    return {
      sponsoredBy: isProductionEnv ? BUILD_VAR_BRAND_ID_PRODUCTION : BUILD_VAR_BRAND_ID_TEST
    };
  }

  return {};
}

export function changeEmail(email, currentPassword, twoFactorToken, timeZone) {
  const apiParams = {
    username: email,
    currentPassword: currentPassword,
    twoFactorToken: twoFactorToken || null,
    timeZone: timeZone
  };

  return WebappAxios.post('/f/change_username', apiParams).then((response) => {
    return response.data;
  });
}

export interface IPrice {
  netValue: number;
  grossValue: number;
}

interface IPriceWithDiscount extends IPrice {
  discountPercentage: number;
}

type ISubscriptionType = webapp.views.AccountTypeKey;

type IFrequency = 'MONTHLY' | 'YEARLY';

export type IProductType =
  | 'FILEEE_BOX_V2'
  | 'FILEEEBOX_SET_V2'
  | 'Fileee Box Set'
  | 'FileeeBox'
  | 'SIB_SET'
  | 'OEVO_SET'
  | 'FILEEE_BOX_DIY'
  | 'FILEEE_BOX_DIY+'
  | 'TEAM_SEATS'
  | 'PURCHASED_DOCUMENTS'
  | 'purchasableDocuments1-10'
  | 'purchasableDocuments1-1000';

type ISubscriptionItemPrice = {
  MONTHLY: IPrice;
  YEARLY: IPrice;
};

type ISubscriptionItemPriceWithDiscount = {
  MONTHLY: IPriceWithDiscount;
  YEARLY: IPriceWithDiscount;
};

type ISubscriptionPrice = {
  id: ISubscriptionType;
  basePrice: IPrice;
  name: string;
  frequency: IFrequency;
  monthlyPrice: IPrice;
  paymentPrice: IPrice;
  discountPercentage?: number;
};

type IProductPrice = {
  id: IProductType;
  basePrice: IPrice;
  name: string;
  subscriptionItemPrices: {
    Free: ISubscriptionItemPrice;
    Premium: ISubscriptionItemPriceWithDiscount;
    Professional: ISubscriptionItemPriceWithDiscount;
  };
};

export type ICartParams = { currentDomain: string; products: ICartProduct[] };

export type ICartProduct = {
  image?: string;
  category?: string;
  code: string;
  name: string;
  parent_code?: string;
  price: number;
  quantity?: number;
  quantity_max?: number;
  quantity_min?: number;
  sub_enddate?: string;
  sub_frequency?: string;
  sub_startdate?: string;
};

export type IPriceListResponse = {
  vat: number;
  vatOriginCountry: 'DE';
  subscriptionPrices: ISubscriptionPrice[];
  productPrices: IProductPrice[];
};

export function getPriceList(): Promise<IPriceListResponse> {
  return WebappAxios.get('/store/pricelist').then((response) => {
    return transformPriceListResponse(response.data);
  });
}

export type INextInvoiceResponse = {
  price: IPrice;
  dueDate: string;
};

/* Note: productIds can be webapp.views.AccountTypeKey | 'addon:team_seats' or comma separated ids */
export function getNextInvoice(productIds: string): Promise<INextInvoiceResponse> {
  return WebappAxios.get(`/store/nextinvoice?productIds=${productIds}`).then((response) => {
    return response.data;
  });
}

export type IStoreLinkResponse = {
  link: string;
  signed: boolean;
  totalAmount: number;
};

export function getSignedStoreLink(cartParams: ICartParams): Promise<IStoreLinkResponse> {
  return WebappAxios.post('/store/products/link', cartParams).then((response) => {
    try {
      let responseData: IStoreLinkResponse = response.data;

      console.log(responseData.link);

      let modifiedUrl = new URL(responseData.link);

      let trackingParams = getTrackingParamsToForwardToServer();

      Object.keys(trackingParams).forEach((key) => {
        if (trackingParams[key] && SupportedFoxyCartTrackingParams.includes(key)) {
          if (key === BelboonParam) {
            modifiedUrl.searchParams.append(BelboonParamForFoxycart, trackingParams[key]);
          } else {
            modifiedUrl.searchParams.append(key, trackingParams[key]);
          }
        }
      });

      return {
        ...responseData,
        link: modifiedUrl.toString()
      };
    } catch (error) {
      return response.data;
    }
  });
}

export function getSubscriptionUpgradeLink(
  accountTypeId: webapp.views.AccountTypeKey,
  frequency: 'MONTHLY' | 'YEARLY'
): Promise<IStoreLinkResponse> {
  const searchParams = new URLSearchParams();
  searchParams.append('subscriptionId', accountTypeId);
  searchParams.append('frequency', frequency);
  return WebappAxios.get(`/store/subscription/upgrade?${searchParams.toString()}`).then(
    (response) => {
      return response.data;
    }
  );
}

export function get2023SubscriptionUpgradeLink(
  accountTypeId: webapp.views.AccountTypeKey
): Promise<IStoreLinkResponse> {
  const searchParams = new URLSearchParams();
  searchParams.append('accountType', accountTypeId);
  return WebappAxios.get(`/store/subscription/custom/2023_upgrade?${searchParams.toString()}`).then(
    (response) => {
      return response.data;
    }
  );
}

type IPaymentDetailsResponse = { link?: string; linkAvailable: boolean };
export function getPaymentDetailsLink(): Promise<IPaymentDetailsResponse> {
  return WebappAxios.get('/store/payment/update').then((response) => {
    return response.data;
  });
}

export type ISubscriptionOptionsResponse = {
  availableOptions: {
    plan: webapp.views.AccountTypeKey;
    frequency: 'YEARLY' | 'MONTHLY';
    firstRegularPayment: string;
    oneTimePayment: number;
    upgradeLink: string;
  }[];
};

export function getSubscriptionOptions(): Promise<ISubscriptionOptionsResponse> {
  return WebappAxios.get('/store/subscription/options').then((response) => {
    return response.data;
  });
}

export function revokeCancelledSubscription(): Promise<AxiosResponse<void>> {
  return WebappAxios.post('/store/subscription/revoke-cancellation');
}

export function createDeleteAccountRequest() {
  return WebappAxios.post('/delete-account/create-delete-request');
}

export function checkDeleteAccountToken(token: string): Promise<{ success: boolean }> {
  return WebappAxios.post('/delete-account/check-token', { token: token }).then((response) => {
    return response.data;
  });
}

type DeleteAccountData = {
  email?: string;
  password?: string;
  token: string;
  twoFactorAuth?: string;
  feedback: {
    missingFunction: FeedbackField;
    notUnderstandable: FeedbackField;
    notUseful: FeedbackField;
    rareUsage: FeedbackField;
    causingProblem: FeedbackField;
    other: FeedbackField;
    contactAllowed: FeedbackField;
  };
};
type FeedbackField = { applies: boolean; additionalInformation?: string };

type DeleteAccountResponse = { success: boolean; error: any };

export function deleteAccount(data: DeleteAccountData): Promise<DeleteAccountResponse> {
  return WebappAxios.post('/delete-account/delete-account', data).then((response) => {
    return response.data;
  });
}

export type ForeignAccountTypeJsEnum =
  | 'DROPBOX'
  | 'GOOGLE_DRIVE'
  | 'ONEDRIVE'
  | 'BOX_COM'
  | 'EXPORT'
  | 'EMAIL';

export type CredentialTypeJsEnum =
  | 'GOOGLE'
  | 'DROPBOX'
  | 'MICROSOFT'
  | 'MICROSOFT_EMAIL'
  | 'LEXOFFICE'
  | 'BOX_COM'
  | 'BEELINE'
  | 'WEBDAV';

export function redirectToConnectExternalLinkedService({
  foreignAccountType,
  credentialType
}: {
  foreignAccountType: ForeignAccountTypeJsEnum;
  credentialType: CredentialTypeJsEnum;
}): Promise<AxiosResponse<string>> {
  return WebappAxios.post('/linked-services/link', {
    foreignAccountId: null,
    foreignAccountType: foreignAccountType,
    credentialType: credentialType
  });
}

export function redirectToConnectExistingExternalLinkedService({
  foreignAccountId,
  foreignAccountType,
  credentialType
}: {
  foreignAccountId: string;
  foreignAccountType: ForeignAccountTypeJsEnum;
  credentialType: CredentialTypeJsEnum;
}) {
  return WebappAxios.post('/linked-services/link', {
    foreignAccountId,
    foreignAccountType: foreignAccountType,
    credentialType: credentialType
  });
}

export function postAddRevisionLock({ documentId, revisionInfo }) {
  return WebappAxios.post(`/documents/rest/${documentId}/revision-lock`, revisionInfo);
}

export function postRequestRemoveSso() {
  return WebappAxios.post('/sso/request-remove-sso');
}

export function postRequestRemoveSsoWithToken(token: string, password: string) {
  return WebappAxios.post(`/sso/remove-sso/${token}`, {
    newPassword: password
  });
}

export function transformPriceListResponse(priceList: IPriceListResponse): IPriceListResponse {
  let newResponse = { ...priceList };

  // @ts-ignore
  newResponse.subscriptionPrices = priceList.subscriptionPrices.map((oldValue) => {
    if (oldValue.id === 'Free') {
      return {
        ...oldValue,
        id: 'free'
      };
    } else {
      return oldValue;
    }
  });

  newResponse.productPrices = priceList.productPrices.map((productPrice) => {
    let newSubscriptionItemPrices = { ...productPrice.subscriptionItemPrices };
    if (productPrice.subscriptionItemPrices.Free) {
      newSubscriptionItemPrices['free'] = productPrice.subscriptionItemPrices.Free;
    }
    return {
      ...productPrice,
      subscriptionItemPrices: newSubscriptionItemPrices
    };
  });

  return newResponse;
}

const LoginOptionsApiBaseURL = '/login-options';

export type IApiLoginOption = {
  id: SupportedLoginOptionsEnum;
  name: string;
  status: 'ACTIVE' | 'PENDING' | 'EXPIRING';
  info: string;
};

export const loginOptionsApi = {
  getLoginOptions: () => {
    return WebappAxios.get(LoginOptionsApiBaseURL).then((response) => {
      return response.data as IApiLoginOption[];
    });
  },

  addLoginOption: (providerId: SupportedLoginOptionsEnum) => {
    return WebappAxios.post(`${LoginOptionsApiBaseURL}/start-add-flow/${providerId}`);
  },

  removeLoginOption: (providerId: SupportedLoginOptionsEnum) => {
    return WebappAxios.post(`${LoginOptionsApiBaseURL}/remove/${providerId}`);
  },

  addFileeeLogin: () => {
    return WebappAxios.post(`${LoginOptionsApiBaseURL}/add/email`);
  },

  finishAddEmailLogin: (resetToken, newPassword) => {
    return WebappAxios.post(`${LoginOptionsApiBaseURL}/finish-add-email-login/${resetToken}`, {
      newPassword: newPassword
    });
  }
};
